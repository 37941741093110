import store from '../store/index'
import { switchLoading } from '../methods/global/loading'
import { getCookie } from '../methods/global/cookie'
import fixCircular from 'circular-json'
import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'
// 測試頁面
const Test = () => import('@/views/TestViews/Test.vue')
// 一般頁面 (登入/註冊/忘記密碼進入點)
const General = () => import('@/views/general/General.vue')
// 法律相關頁面
const Service = () => import('@/views/law/Service.vue')
const Privacy = () => import('@/views/law/Privacy.vue')
// 登入頁面
const Entrance = () => import('@/views/general/Entrance.vue')
const Login = () => import('@/views/general/Login.vue')
const CustomerLogin = () => import('@/views/general/CustomerLogin.vue')
// 註冊頁面
const Register = () => import('@/views/general/Register.vue')
// 忘記密碼
const ForgotPassword = () => import('@/views/general/ForgotPassword.vue')
// 重設密碼
const ResetPassword = () => import('@/views/general/ResetPassword.vue')
// sidebar
const UserSideBar = () => import('@/views/user/UserSideBar.vue')
const SellerSideBar = () => import('@/views/seller/SellerSideBar.vue')
// * FB
const RedirectFb = () => import('@/views/fb/RedirectFb.vue')
const AuthFb = () => import('@/views/fb/AuthFb.vue')
const FbApp = () => import('@/views/fb/FbApp.vue')
const FbSideBar = () => import('@/views/fb/FbSideBar.vue')
const Fb_MerchOrder = () => import('@/views/fb/MerchOrder.vue')
const Fb_Bill = () => import('@/views/fb/Bill.vue')
const AuthFbToShop = () => import('@/views/fb/AuthFbToShop.vue')
// * 賣家個人
const S_LinkAccounts = () => import('@/views/user/LinkAccounts.vue')
const S_ReceiptInfos = () => import('@/views/user/ReceiptInfos.vue')
const StoreLists = () => import('@/views/user/StoreLists.vue')
const PaymentRecord = () => import('@/views/user/payPlan/PaymentRecord')
const PayPlan = () => import('@/views/user/payPlan/PayPlan.vue')
const MoneyTransfer = () => import('@/views/user/payPlan/MoneyTransfer.vue')
// * 賣家
// 商品管理
const Merchandise = () => import("@/views/seller/merchandise/Merchandise.vue")
const MerchandiseTest = () => import("@/views/seller/merchandise/MerchandiseTest.vue")
const OldMerchandise = () => import('@/views/seller/merchandise/OldMerchandise.vue')
const MerchandiseInfo = () => import('@/views/seller/merchandise/MerchandiseInfo.vue')
const Detail = () => import('@/views/seller/merchandise/Detail.vue')
const Orders = () => import('@/views/seller/merchandise/Orders.vue')
const Stocks = () => import('@/views/seller/merchandise/Stocks.vue')
const OrderGoods = () => import('@/views/seller/merchandise/OrderGoods.vue')
const Warehousing = () => import('@/views/seller/merchandise/Warehousing.vue')
const CreatePurchaseOrder = () => import('@/views/seller/merchandise/CreatePurchaseOrder.vue')
const EditMerchandise = () => import('@/views/seller/merchandise/EditMerchandise.vue')
const BatchCreateMerchandise = () => import('@/views/seller/merchandise/BatchCreateMerchandise.vue')
const SynchronizeFBArticle = () => import('@/views/seller/merchandise/SynchronizeFBArticle.vue')
const BatchHandleFbMessages = () => import('@/views/seller/merchandise/BatchHandleFbMessages.vue')
const SyncFbExtentionArticle = () => import('@/views/seller/merchandise/SyncFbExtentionArticle.vue')
const CopyMerchandise = () => import('@/views/seller/merchandise/CopyMerchandise.vue')
// 訂單管理
const MerchOrder = () => import('@/views/seller/merchOrder/MerchOrder.vue')
const CheckoutOrder = () => import('@/views/seller/merchOrder/CheckoutOrder.vue')
const OldCheckoutOrder = () => import('@/views/seller/merchOrder/OldCheckoutOrder.vue')
// 庫存管理
const Stock = () => import('@/views/seller/stock/Stock.vue')
const OldStock = () => import('@/views/seller/stock/OldStock.vue')
const EditStock = () => import('@/views/seller/stock/EditStock.vue')
// 叫貨管理
const PurchaseOrder = () => import('@/views/seller/purchaseOrder/PurchaseOrder.vue')
// 顧客管理
const Participant = () => import('@/views/seller/participant/Participant.vue')
const OldParticipant = () => import('@/views/seller/participant/OldParticipant.vue')
const ParticipantInfo = () => import('@/views/seller/participant/ParticipantInfo.vue')
const P_MerchOrder = () => import('@/views/seller/participant/MerchOrder.vue')
const P_Bill = () => import('@/views/seller/participant/Bill.vue')
const P_ShoppingGold = () => import('@/views/seller/participant/ShoppingGold.vue')
const P_Receiving = () => import('@/views/seller/participant/Receiving.vue')
// 賣場設定
const VipsAndGroups = () => import('@/views/seller/setting/VipsAndGroups.vue')
const PersonalStore = () => import('@/views/seller/setting/PersonalStore.vue')
const Social = () => import('@/views/seller/setting/Social.vue')
const Suppliers = () => import('@/views/seller/setting/Suppliers.vue')
const ShippingPayments = () => import('@/views/seller/setting/ShippingPayments.vue')
const Business = () => import('@/views/seller/setting/Business.vue')
const Promotions = () => import('@/views/seller/setting/Promotions.vue')
const PreferSetting = () => import('@/views/seller/setting/prefer/PreferSetting.vue')
const PS_Merchandise = () => import('@/views/seller/setting/prefer/PS_Merchandise.vue')
const PS_MerchOrder = () => import('@/views/seller/setting/prefer/PS_MerchOrder.vue')
const PS_Stock = () => import('@/views/seller/setting/prefer/PS_Stock.vue')
const PS_PurchaseOrder = () => import('@/views/seller/setting/prefer/PS_PurchaseOrder.vue')
const PS_Participant = () => import('@/views/seller/setting/prefer/PS_Participant.vue')
const PS_Bill = () => import('@/views/seller/setting/prefer/PS_Bill.vue')
const PS_Shipper = () => import('@/views/seller/setting/prefer/PS_Shipper.vue')
const PS_CommonStyles = () => import('@/views/seller/setting/prefer/PS_CommonStyles.vue')
const PS_Line = () => import('@/views/seller/setting/prefer/PS_Line.vue')
const PS_Fb = () => import('@/views/seller/setting/prefer/PS_Fb.vue')
const PS_Backend = () => import('@/views/seller/setting/prefer/PS_Backend.vue')
// 對帳出貨
const Bill = () => import('@/views/seller/bill/Bill.vue')
const EditBill = () => import('@/views/seller/bill/EditBill.vue')
const MergeBill = () => import('@/views/seller/bill/Merge.vue')
const SplitBill = () => import('@/views/seller/bill/SplitBill.vue')
const Inspection = () => import('@/views/seller/bill/Inspection.vue')
const Shipper = () => import('@/views/seller/bill/Shipper.vue')
// 儀錶板
const Performance = () => import('@/views/seller/dashboard/Performance.vue')
const Ranking = () => import('@/views/seller/dashboard/Ranking.vue')
const PushingHistories = () => import('@/views/seller/line/PushingHistories.vue')
// Line 功能
const Live = () => import('@/views/seller/line/Live.vue')
const WaitingPush = () => import('@/views/seller/line/WaitingPush.vue')
const MakeWishes = () => import('@/views/seller/line/MakeWishes.vue')
// Line 内嵌頁面
const WishesPage = () => import('@/views/seller/line/page/WishesPage.vue')
const PushOrderPage = () => import('@/views/seller/line/page/PushOrderPage.vue')
const LineMerchInfo = () => import('@/views/seller/line/page/LineMerchInfo.vue')
const Line_MerchOrder = () => import('@/views/seller/line/page/MerchOrder.vue')
const Line_Bill = () => import('@/views/seller/line/page/Bill.vue')
const Line_LiveOrderPage = () => import('@/views/seller/line/page/LiveOrderPage.vue')
// 行銷活動
const Lottery = () => import('@/views/seller/marketing/Lottery.vue')
const LotteryRecord = () => import('@/views/seller/marketing/LotteryRecord.vue')
// * 顧客個人
const CustomerInfo = () => import('@/views/customer/user/Customer.vue')
const C_LinkAccounts = () => import('@/views/customer/user/LinkAccounts.vue')
const C_ReceiptInfos = () => import('@/views/customer/user/ReceiptInfos.vue')
const C_StoreLists = () => import('@/views/customer/user/StoreLists.vue')
// * 顧客
const CustomerSideBar = () => import('@/views/customer/CustomerSideBar.vue')
const C_Overview = () => import('@/views/customer/overview/Overview.vue')
const C_Shop = () => import('@/views/customer/shop/Shop.vue')
const C_oldShop = () => import('@/views/customer/shop/oldShop.vue')
const C_OrderPage = () => import('@/views/customer/shop/OrderPage.vue')
const C_MerchOrder = () => import('@/views/customer/merchOrder/MerchOrder.vue')
const C_CheckOutOrder = () => import('@/views/customer/merchOrder/CheckOutOrder.vue')
const C_Bill = () => import('@/views/customer/bill/Bill.vue')
// Line callback
const LineCallBackForLogin = () => import('@/components/redirect/LineCallBackForLogin.vue')
const LineCallBackForNotify = () => import('@/components/redirect/LineCallBackForNotify.vue')
// shortcut
const ShortCut = () => import('@/components/redirect/ShortCut.vue')
// 信箱驗證
const VerifyEmail = () => import('@/components/redirect/VerifyEmail.vue')
// 404
const NotFound = () => import('@/components/redirect/NotFound.vue')

// * 後台
const Backend_Login = () => import('@/views/backend/Login.vue')
const BackendSideBar = () => import('@/views/backend/BackendSideBar.vue')
const Subscription = () => import('@/views/backend/subscription/Subscription.vue')
const RegisterInfo = () => import('@/views/backend/report/RegisterInfo.vue')
const UsedInfo = () => import('@/views/backend/report/UsedInfo.vue')
const AccountsChange = () => import('@/views/backend/operation/AccountsChange.vue')

const routes = [
  // 測試
  {
    path: '/test',
    name: 'Test',
    component: Test
  },
  // 信箱驗證
  {
    path: '/user/verifyEmail',
    name: 'VerifyEmail',
    component: VerifyEmail,
    meta: {
      title: '信箱驗證',
      checkLogin: false,
      currentModal: 'general',
    },
  },
  // line callback (line 登入與綁定)
  {
    path: '/apps/line/login',
    name: 'LineCallBackForLogin',
    component: LineCallBackForLogin,
    meta: {
      title: 'Line',
      checkLogin: false,
      currentModal: 'general',
    },
  },
  {
    path: '/apps/line/notify',
    name: 'LineCallBackForNotify',
    component: LineCallBackForNotify,
    meta: {
      title: 'Line-Notify',
      checkLogin: false,
      currentModal: 'general',
    },
  },
  // 短網址
  {
    path: '/s/:shortCut',
    name: 'ShortCut',
    component: ShortCut,
    meta: {
      title: '短網址',
      checkLogin: false,
      currentModal: 'general',
    },
  },
  // 服務條款
  {
    path: '/ETwingo/serve',
    name: 'Service',
    component: Service,
    meta: {
      title: '服務條款',
      checkLogin: false,
      currentModal: 'general',
    },
  },
  // 隱私權政策
  {
    path: '/ETwingo/privacy',
    name: 'Privacy',
    component: Privacy,
    meta: {
      title: '隱私權政策',
      checkLogin: false,
      currentModal: 'general',
    },
  },
  // 一般頁面
  {
    path: '/',
    name: 'General',
    component: General,
    meta: {
      currentModal: 'general',
    },
    children: [
      // 入口
      {
        path: '/',
        name: 'Entrance',
        component: Entrance,
        meta: {
          title: '',
          checkLogin: false,
        },
      },
      // 賣家登入頁面
      {
        path: 'sellerLogin',
        name: 'Login',
        component: Login,
        meta: {
          title: '賣家登入',
          checkLogin: false,
        },
      },
      // 顧客登入頁面
      {
        path: 'customerLogin',
        name: 'CustomerLogin',
        component: CustomerLogin,
        meta: {
          title: '顧客登入',
          checkLogin: false,
        },
      },
      // 註冊頁面
      {
        path: 'register',
        name: 'Register',
        component: Register,
        meta: {
          title: '註冊',
          checkLogin: false,
        },
      },
      // 忘記密碼
      {
        path: 'forgotPassword',
        name: 'ForgotPassword',
        component: ForgotPassword,
        meta: {
          title: '忘記密碼',
          checkLogin: false,
        },
      },
      // 重設密碼
      {
        path: 'resetPassword',
        name: 'ResetPassword',
        component: ResetPassword,
        meta: {
          title: '重設密碼',
          checkLogin: false,
        },
      },
      // 後臺登入
      {
        path: 'backend/login',
        name: 'Backend_Login',
        component: Backend_Login,
        meta: {
          title: '後台登入',
          checkLogin: false,
        },
      },
    ],
  },
  // fb 使用者可以用 https://twingo1.uniten.com.tw/authFb/{storeId} 此連結放置頂文讓顧客強制登入授權
  {
    path: '/authFb/:storeId',
    name: 'AuthFbToShop',
    component: AuthFbToShop,
    meta: {
      title: 'FB 授權後進個人賣場',
      checkLogin_fb: false,
      currentModal: 'fb',
    },
  },
  {
    path: '/authFb',
    name: 'AuthFb',
    component: AuthFb,
    meta: {
      title: 'FB 授權頁面',
      checkLogin_fb: false,
      currentModal: 'fb',
    },
  },
  // Fb 登入後轉址頁面
  {
    path: '/redirectFb',
    name: 'RedirectFb',
    component: RedirectFb,
    meta: {
      title: 'FB 登入中...',
      checkLogin_fb: false,
      currentModal: 'fb',
    },
  },
  // Fb 免註冊相關頁面
  {
    path: '/fb',
    name: 'FbApp',
    component: FbApp,
    meta: {
      checkLogin_fb: true,
      currentModal: 'fb',
    },
    children: [
      {
        path: ':storeId',
        name: 'FbSideBar',
        component: FbSideBar,
        meta: {
          checkLogin_fb: true,
          currentModal: 'fb',
        },
        children: [
          {
            path: 'merchOrder',
            name: 'Fb_MerchOrder',
            component: Fb_MerchOrder,
            meta: {
              title: '我的訂單',
              currentModal: 'fb',
            },
          },
          {
            path: 'bill',
            name: 'Fb_Bill',
            component: Fb_Bill,
            meta: {
              title: '我的帳單',
              currentModal: 'fb',
            },
          },
        ],
      },
    ]
  },
  // * 個人 (模組)
  {
    path: '/user',
    name: 'UserSideBar',
    component: UserSideBar,
    meta: {
      currentModal: 'user'
    },
    children: [
      // 關聯帳號頁面
      {
        path: 'linkAccounts',
        name: 'S_LinkAccounts',
        component: S_LinkAccounts,
        meta: {
          title: '關聯帳號',
          checkLogin: true,
        },
      },
      // 收件資訊頁面
      {
        path: 'receiptInfos',
        name: 'S_ReceiptInfos',
        component: S_ReceiptInfos,
        meta: {
          title: '收件資訊',
          checkLogin: true,
        },
      },
      // 賣場列表頁面
      {
        path: 'storeLists',
        name: 'StoreLists',
        component: StoreLists,
        meta: {
          title: '賣場列表',
          checkLogin: true,
        },
      },
      // 付費紀錄
      {
        path: 'paymentRecord',
        name: 'PaymentRecord',
        component: PaymentRecord,
        meta: {
          title: '付費紀錄',
          checkLogin: true,
        },
      },
      // 付費方案頁面
      {
        path: 'payPlan',
        name: 'PayPlan',
        component: PayPlan,
        meta: {
          title: '付費方案',
          checkLogin: true,
          checkRouterFrom: 'Privacy'
        },
      },
      // 付款回報頁面
      {
        path: 'moneyTransfer',
        name: 'MoneyTransfer',
        component: MoneyTransfer,
        meta: {
          title: '付款回報',
          checkLogin: true,
          checkRouterFrom: 'PayPlan'
        },
      },
    ],
  },
  // * 賣家 (模組)
  {
    path: '/seller/store/:storeId',
    name: 'SellerSideBar',
    component: SellerSideBar,
    meta: {
      checkLogin: true,
      currentModal: 'seller',
      verify: true,
    },
    children: [
      // 新商品管理 (包含新舊合併的取留言方式)
      {
        path: 'merchandise',
        name: 'Merchandise',
        component: Merchandise,
        meta: {
          title: '商品管理',
        },
      },
      // 舊版取留言方式的商品管理
      {
        path: 'MerchandiseTest',
        name: 'MerchandiseTest',
        component: MerchandiseTest,
        meta: {
          title: '商品管理',
        },
      },
      // getSiteObjects 版本的商品管理
      {
        path: 'oldMerchandise',
        name: 'OldMerchandise',
        component: OldMerchandise,
        meta: {
          title: '舊版商品管理',
        },
      },
      // 訂單管理
      {
        path: 'merchOrder',
        name: 'MerchOrder',
        component: MerchOrder,
        meta: {
          title: '訂單管理',
        },
      },
      // 庫存管理
      {
        path: 'stock',
        name: 'Stock',
        component: Stock,
        meta: {
          title: '庫存管理',
        },
      },
      // 舊版庫存管理
      {
        path: 'oldStock',
        name: 'OldStock',
        component: OldStock,
        meta: {
          title: '舊版庫存管理',
        },
      },
      // 叫貨管理
      {
        path: 'purchaseOrder',
        name: 'PurchaseOrder',
        component: PurchaseOrder,
        meta: {
          title: '叫貨管理',
        },
      },
      // 新版顧客管理
      {
        path: 'participant',
        name: 'Participant',
        component: Participant,
        meta: {
          title: '顧客管理',
        },
      },
      // 舊版顧客管理
      {
        path: 'oldParticipant',
        name: 'OldParticipant',
        component: OldParticipant,
        meta: {
          title: '舊版顧客管理',
        },
      },
      // 對帳出貨
      {
        path: 'bill',
        name: 'Bill',
        component: Bill,
        meta: {
          title: '對帳出貨',
        },
      },
      // 賣場設定
      // VIP/群組管理
      {
        path: 'vipsAndGroups',
        name: 'VipsAndGroups',
        component: VipsAndGroups,
        meta: {
          title: 'VIP/群組管理',
        },
      },
      // 個人賣場
      {
        path: 'personalStore',
        name: 'PersonalStore',
        component: PersonalStore,
        meta: {
          title: '個人賣場',
        },
      },
      // 社群平台
      {
        path: 'social',
        name: 'Social',
        component: Social,
        meta: {
          title: '社群平台',
        },
      },
      // 供應商
      {
        path: 'suppliers',
        name: 'Suppliers',
        component: Suppliers,
        meta: {
          title: '供應商',
        },
      },
      // 結單/出貨/運送/付款
      {
        path: 'shippingPayments',
        name: 'ShippingPayments',
        component: ShippingPayments,
        meta: {
          title: '結單/出貨/運送/付款',
        },
      },
      // 企業揭漏
      {
        path: 'business',
        name: 'Business',
        component: Business,
        meta: {
          title: '企業資訊',
        },
      },
      // 偏好設定
      {
        path: 'preferSetting',
        name: 'PreferSetting',
        component: PreferSetting,
        meta: {
          checkLogin: true,
        },
        children: [
          // 偏好商品
          {
            path: 'merchandise',
            name: 'PS_Merchandise',
            component: PS_Merchandise,
            meta: {
              title: '偏好商品',
            },
          },
          // 偏好訂單
          {
            path: 'merchOrder',
            name: 'PS_MerchOrder',
            component: PS_MerchOrder,
            meta: {
              title: '偏好訂單',
            },
          },
          // 偏好庫存
          {
            path: 'stock',
            name: 'PS_Stock',
            component: PS_Stock,
            meta: {
              title: '偏好庫存',
            },
          },
          // 偏好叫貨
          {
            path: 'purchaseOrder',
            name: 'PS_PurchaseOrder',
            component: PS_PurchaseOrder,
            meta: {
              title: '偏好叫貨',
            },
          },
          // 偏好顧客
          {
            path: 'participant',
            name: 'PS_Participant',
            component: PS_Participant,
            meta: {
              title: '偏好顧客',
            },
          },
          // 偏好對帳
          {
            path: 'bill',
            name: 'PS_Bill',
            component: PS_Bill,
            meta: {
              title: '偏好對帳',
            },
          },
          // 偏好撿貨出貨
          {
            path: 'shipper',
            name: 'PS_Shipper',
            component: PS_Shipper,
            meta: {
              title: '偏好撿貨出貨',
            },
          },
          // 偏好常用款式
          {
            path: 'commonStyles',
            name: 'PS_CommonStyles',
            component: PS_CommonStyles,
            meta: {
              title: '偏好常用款式',
            },
          },
          // 偏好 Line
          {
            path: 'line',
            name: 'PS_Line',
            component: PS_Line,
            meta: {
              title: '偏好Line',
            },
          },
          // 偏好 Fb
          {
            path: 'fb',
            name: 'PS_Fb',
            component: PS_Fb,
            meta: {
              title: '偏好Fb',
            },
          },
          // 偏好(特地給後台人員用，目的是要可以控制該賣場特殊設定)
          {
            path: 'backend',
            name: 'PS_Backend',
            component: PS_Backend,
            meta: {
              title: '偏好Fb(後台人員專屬)',
            },
          },
        ],
      },
      // 促銷活動
      {
        path: 'promotions',
        name: 'Promotions',
        component: Promotions,
        meta: {
          title: '促銷活動',
        },
      },
      // 業績總覽
      // {
      //   path: 'performance',
      //   name: 'Performance',
      //   component: Performance,
      //   meta: {
      //     title: '業績總覽',
      //   },
      // },
      // 排行榜
      // {
      //   path: 'ranking',
      //   name: 'Ranking',
      //   component: Ranking,
      //   meta: {
      //     title: '排行榜',
      //   },
      // },
      // 推播歷程
      {
        path: 'pushingHistories',
        name: 'PushingHistories',
        component: PushingHistories,
        meta: {
          title: '推播歷程',
        },
      },
      // 直播間
      {
        path: 'live',
        name: 'Live',
        component: Live,
        meta: {
          title: '直播間',
        },
      },
      // 待推播列表
      {
        path: 'waitingPush',
        name: 'WaitingPush',
        component: WaitingPush,
        meta: {
          title: '待推播列表',
        },
      },
      // 許願管理
      {
        path: 'makeWishes',
        name: 'MakeWishes',
        component: MakeWishes,
        meta: {
          title: '許願管理',
        },
      },
      // 抽獎紀錄
      {
        path: 'lotteryRecord',
        name: 'LotteryRecord',
        component: LotteryRecord,
        meta: {
          title: '抽獎紀錄',
        },
      },
    ],
  },
  // * 顧客 (模組)
  {
    path: '/customer/store/:storeId',
    name: 'CustomerSideBar',
    component: CustomerSideBar,
    meta: {
      checkLogin: true,
      currentModal: 'customer',
      verify: true,
    },
    children: [
      // 個人總攬
      {
        path: 'overview',
        name: 'C_Overview',
        component: C_Overview,
        meta: {
          title: '賣場總攬',
        },
      },
      // (舊)個人賣場，getSiteObjects 版本的個人賣場
      {
        path: 'oldshop',
        name: 'C_oldShop',
        component: C_oldShop,
        meta: {
          title: '(舊)個人賣場',
        },
      },
      // 
      {
        path: 'shop',
        name: 'C_Shop',
        component: C_Shop,
        meta: {
          title: '個人賣場',
        },
      },
      // 我的訂單
      {
        path: 'merchOrder',
        name: 'C_MerchOrder',
        component: C_MerchOrder,
        meta: {
          title: '我的訂單',
        },
      },
      // 下單頁面
      {
        path: 'orderPage',
        name: 'C_OrderPage',
        component: C_OrderPage,
        meta: {
          title: '商品下單',
        },
      },
      // 帳單
      {
        path: 'bill',
        name: 'C_Bill',
        component: C_Bill,
        meta: {
          title: '我的帳單',
        },
      },
    ],
  },
  // * 顧客 > 個人頁面
  {
    path: '/customer',
    name: 'CustomerInfo',
    component: CustomerInfo,
    meta: {
      checkLogin: true,
      currentModal: 'customerInfo',
    },
    children: [
      // 賣場列表 
      {
        path: 'storeLists',
        name: 'C_StoreLists',
        component: C_StoreLists,
        meta: {
          title: '賣場列表',
        },
      },
      // 關聯帳號
      {
        path: 'linkAccounts',
        name: 'C_LinkAccounts',
        component: C_LinkAccounts,
        meta: {
          title: '關聯帳號',
        },
      },
      // 收件資訊
      {
        path: 'receiptInfos',
        name: 'C_ReceiptInfos',
        component: C_ReceiptInfos,
        meta: {
          title: '收件資訊',
        },
      },
    ],
  },
  // * 後台 (模組)
  {
    path: '/backend',
    name: 'BackendSideBar',
    component: BackendSideBar,
    meta: {
      checkLogin_backend: true,
      currentModal: 'backend',
    },
    children: [
      // 訂閱列表
      {
        path: 'subscription',
        name: 'Subscription',
        component: Subscription,
        meta: {
          title: '訂閱列表',
        },
      },
      // 註冊資訊
      {
        path: 'registerInfo',
        name: 'RegisterInfo',
        component: RegisterInfo,
        meta: {
          title: '註冊資訊',
        },
      },
      // 使用資訊
      {
        path: 'usedInfo',
        name: 'UsedInfo',
        component: UsedInfo,
        meta: {
          title: '使用資訊',
        },
      },
      // 帳號相關操作
      {
        path: 'accountsChange',
        name: 'AccountsChange',
        component: AccountsChange,
        meta: {
          title: '顧客帳號異動',
        },
      },
    ],
  },
  // 編輯庫存
  {
    path: '/seller/store/:storeId/editStock',
    name: 'EditStock',
    component: EditStock,
    meta: {
      title: '編輯庫存',
      checkLogin: true,
      currentModal: 'seller',
      verify: true,
    },
  },
  // 現貨入庫
  {
    path: '/seller/store/:storeId/warehousing',
    name: 'Warehousing',
    component: Warehousing,
    meta: {
      title: '現貨入庫',
      checkLogin: true,
      currentModal: 'seller',
      verify: true,
    },
  },
  // 建立叫貨單
  {
    path: '/seller/store/:storeId/createPurchaseOrder',
    name: 'CreatePurchaseOrder',
    component: CreatePurchaseOrder,
    meta: {
      title: '建立叫貨單',
      checkLogin: true,
      currentModal: 'seller',
      verify: true,
    },
  },
  // 編輯商品
  {
    path: '/seller/store/:storeId/editMerchandise',
    name: 'EditMerchandise',
    component: EditMerchandise,
    meta: {
      title: '編輯商品',
      checkLogin: true,
      currentModal: 'seller',
      verify: true,
    },
  },
  // 商品詳細資訊
  {
    path: '/seller/store/:storeId/merchandiseInfo',
    name: 'MerchandiseInfo',
    component: MerchandiseInfo,
    meta: {
      checkLogin: true,
      currentModal: 'seller',
      verify: true,
    },
    children: [
      {
        path: 'detail',
        name: 'Detail',
        component: Detail,
        meta: {
          title: '詳細資訊'
        },
      },
      {
        path: 'orders',
        name: 'Orders',
        component: Orders,
        meta: {
          title: '留言與訂單'
        },
      },
      {
        path: 'stocks',
        name: 'Stocks',
        component: Stocks,
        meta: {
          title: '庫存紀錄'
        },
      },
      {
        path: 'orderGoods',
        name: 'OrderGoods',
        component: OrderGoods,
        meta: {
          title: '叫貨紀錄'
        },
      },
    ],
  },
  // 批次建立商品
  {
    path: '/seller/store/:storeId/batchCreateMerchandise',
    name: 'BatchCreateMerchandise',
    component: BatchCreateMerchandise,
    meta: {
      title: '批次建立商品',
      checkLogin: true,
      currentModal: 'seller',
      verify: true,
    },
  },
  // 同步FB文章結果
  {
    path: '/seller/store/:storeId/synchronizeFBArticle',
    name: 'SynchronizeFBArticle',
    component: SynchronizeFBArticle,
    meta: {
      title: '建立FB文章商品',
      checkLogin: true,
      currentModal: 'seller',
      verify: true,
    },
  },
  // 同步FB擴充文章結果
  {
    path: '/seller/store/:storeId/syncFbExtentionArticle',
    name: 'SyncFbExtentionArticle',
    component: SyncFbExtentionArticle,
    meta: {
      title: 'FB擴充建立商品',
      checkLogin: true,
      currentModal: 'seller',
      verify: true,
    },
  },
  // 複製商品
  {
    path: '/seller/store/:storeId/copyMerchandise',
    name: 'CopyMerchandise',
    component: CopyMerchandise,
    meta: {
      title: '複製商品',
      checkLogin: true,
      currentModal: 'seller',
      verify: true,
    },
  },
  // 結單
  {
    path: '/seller/store/:storeId/checkoutOrder/:participantId',
    name: 'CheckoutOrder',
    component: CheckoutOrder,
    meta: {
      title: '結單',
      checkLogin: true,
      currentModal: 'seller',
      verify: true,
    },
  },
  {
    path: '/seller/store/:storeId/OldCheckoutOrder/:participantId',
    name: 'OldCheckoutOrder',
    component: OldCheckoutOrder,
    meta: {
      title: '舊版結單',
      checkLogin: true,
      currentModal: 'seller',
      verify: true,
    },
  },
  // 編輯帳單
  {
    path: '/seller/store/:storeId/editBill/:billHeaderId',
    name: 'EditBill',
    component: EditBill,
    meta: {
      title: '編輯帳單',
      checkLogin: true,
      currentModal: 'seller',
      verify: true,
    },
  },
  // 合併帳單
  {
    path: '/seller/store/:storeId/mergeBill',
    name: 'MergeBill',
    component: MergeBill,
    meta: {
      title: '合併帳單',
      checkLogin: true,
      currentModal: 'seller',
      verify: true,
    },
  },
  // 拆除帳單
  {
    path: '/seller/store/:storeId/splitBill/:billHeaderId',
    name: 'SplitBill',
    component: SplitBill,
    meta: {
      title: '拆除帳單',
      checkLogin: true,
      currentModal: 'seller',
      verify: true,
    },
  },
  // 撿貨總表
  {
    path: '/seller/store/:storeId/inspection',
    name: 'Inspection',
    component: Inspection,
    meta: {
      title: '撿貨總表',
      checkLogin: true,
      currentModal: 'seller',
      verify: true,
    },
  },
  // 撿貨/出貨單
  {
    path: '/seller/store/:storeId/Shipper',
    name: 'Shipper',
    component: Shipper,
    meta: {
      title: '撿貨/出貨單',
      checkLogin: true,
      currentModal: 'seller',
      verify: true,
    },
  },
  // 顧客詳細資訊
  {
    path: '/seller/store/:storeId/participant/:participantId',
    name: 'ParticipantInfo',
    component: ParticipantInfo,
    meta: {
      checkLogin: true,
      currentModal: 'seller',
      verify: true,
    },
    children: [
      // 顧客訂單
      {
        path: 'merchOrder',
        name: 'P_MerchOrder',
        component: P_MerchOrder,
        meta: {
          title: '顧客訂單',
        },
      },
      // 顧客帳單
      {
        path: 'bill',
        name: 'P_Bill',
        component: P_Bill,
        meta: {
          title: '顧客帳單',
        },
      },
      {
        path: 'shoppingGold',
        name: 'P_ShoppingGold',
        component: P_ShoppingGold,
        meta: {
          title: '顧客購物金',
        },
      },
      {
        path: 'receiving',
        name: 'P_Receiving',
        component: P_Receiving,
        meta: {
          title: '顧客收件',
        },
      },
    ]
  },
  // 顧客 > 我的訂單 > 結單
  {
    path: '/customer/store/:storeId/checkOutOrder',
    name: 'C_CheckOutOrder',
    component: C_CheckOutOrder,
    meta: {
      title: '結單',
      checkLogin: true,
      currentModal: 'customer',
      verify: true,
    },
  },
  // 批次處理 FB 留言
  {
    path: '/seller/store/:storeId/batchHandleFbMessages',
    name: 'BatchHandleFbMessages',
    component: BatchHandleFbMessages,
    meta: {
      title: '處理FB留言',
      checkLogin: true,
      currentModal: 'seller',
      verify: true,
    },
  },
  // 顧客抽獎頁面
  {
    path: '/customer/store/:storeId/lottery',
    name: 'Lottery',
    component: Lottery,
    meta: {
      title: '加入抽獎',
      checkLogin: true,
      currentModal: 'customer',
      verify: true,
    },
  },
  // * Line 內嵌
  // Line 許願內嵌
  {
    path: '/line/wishesPage',
    name: 'WishesPage',
    component: WishesPage,
    meta: {
      currentModal: 'line'
    },
  },
  // Line 推播下單內嵌
  {
    path: '/line/pushOrderPage',
    name: 'PushOrderPage',
    component: PushOrderPage,
    meta: {
      currentModal: 'line'
    },
  },
  // Line 商品詳細資訊
  {
    path: '/line/merchInfo',
    name: 'LineMerchInfo',
    component: LineMerchInfo,
    meta: {
      currentModal: 'line'
    },
  },
  // Line 我的訂單
  {
    path: '/line/merchOrder',
    name: 'Line_MerchOrder',
    component: Line_MerchOrder,
    meta: {
      currentModal: 'line'
    },
  },
  // Line 我的帳單
  {
    path: '/line/bill',
    name: 'Line_Bill',
    component: Line_Bill,
    meta: {
      currentModal: 'line'
    },
  },
  // Line 直播下單
  {
    path: '/line/liveOrderPage',
    name: 'Line_LiveOrderPage',
    component: Line_LiveOrderPage,
    meta: {
      currentModal: 'line'
    },
  },
  // * 404
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  switchLoading('show')
  // 做身分紀錄
  if (to.meta.currentModal === 'customer' || to.meta.currentModal === 'customerInfo') {
    window.localStorage.setItem('identity', 'customer')
  } else if (to.meta.currentModal === 'seller' || to.meta.currentModal === 'user') {
    window.localStorage.setItem('identity', 'seller')
  }
  const identity = window.localStorage.getItem('identity') ? window.localStorage.getItem('identity') : 'customer'
  // 先做 # 判斷，若有 # 先移除
  if (to.href) {
    const hrefArray = to.href.split('#')
    console.log(hrefArray)
    // 多判斷 to.href.includes('/#/') 是因為 line 內嵌頁面本身有包含 #，所以要多做處理
    if (hrefArray.length > 1 && (to.href.includes('/#/') || to.href.includes('#/'))) return next(hrefArray[1])
  }
  // 先紀錄路由歷史紀錄，登入時可以用到
  if (to.name !== 'LineCallBackForLogin' && to.name !== 'Privacy' && to.name !== 'Service' 
    && to.meta.currentModal !== 'line' && to.meta.currentModal !== 'fb' && to.name !== 'Inspection'
    && to.name !== 'Shipper' && to.meta.currentModal !== 'general' && to.name !== 'BatchCreateMerchandise'
    && to.name !== 'BatchHandleFbMessages' && to.name !== 'SynchronizeFBArticle' && to.name !== 'NotFound'
    && to.name !== 'SyncFbExtentionArticle' && to.name !== 'CopyMerchandise' && to.name !== 'CheckoutOrder'
    && to.name !== 'C_CheckOutOrder'
  ) {
    let recordTo = {
      fullPath: to.fullPath,
      meta: to.meta,
      params: to.params,
      name: to.name,
      query: to.query
    }
    window.localStorage.setItem('toWhere', fixCircular.stringify(recordTo))
  }
  if (from.name !== 'Login' && from.name !== 'CustomerLogin' && from.name !== 'Entrance' && from.path !== '/') {
    let recordFrom = {
      fullPath: from.fullPath,
      meta: from.meta,
      params: from.params,
      name: from.name,
      query: from.query
    }
    window.localStorage.setItem('fromWhere', fixCircular.stringify(recordFrom))
  }
  const fixdTitle = '易團GO管理系統'
  const token = getCookie('serverToken')
  const token_backend = getCookie('b_serverToken')
  const token_fb = getCookie('fb_serverToken')
  // 動態更換 Title
  if (to.meta.title) {
    document.title = `${to.meta.title}-${fixdTitle}`
  } else {
    document.title = fixdTitle
  }
  // 檢查是否登入
  if ((to.meta.checkLogin && !token) || (to.meta.checkLogin_backend && !token_backend) || (to.meta.checkLogin_fb && !token_fb)) {
    switchLoading('hide')
    alert('您尚未登入，請先登入!')
    if (to.meta.checkLogin) {
      console.log(`router > identity: ${identity}`)
      if (identity === 'seller') return next('/sellerLogin')
      else return next('/customerLogin')
    }
    else if (to.meta.checkLogin_backend) return next({name: 'Backend_Login'})
    else return next({name: 'AuthFb'})
  }
  // 檢查是否需要特定頁面才能進去
  if (to.meta.checkRouterFrom) {
    if (to.meta.checkRouterFrom === from.name) {
      switchLoading('hide')
      return next()
    } else {
      switchLoading('hide')
      alert('請按正常操作進入頁面!')
      return next('/user/paymentRecord')
    }
  }
  // 路由審查
  if (to.meta.verify && to.name !== from.name) {
    // 執行 verify
    store.dispatch('CurrentParticipant/verify', {
      serverToken: token,
      storeId: to.params.storeId
    })
    const verify = store._state.data.CurrentParticipant.participantInfo
    console.log(verify)
    if (verify.code === "200" && verify.data.user.masterId) {
      // 判斷 participant 資訊中，若 user 有 masterId，代表這組 token 是被合併帳號，需要強制讓他登出再次登入
      switchLoading('hide')
      alert('此帳號已被合併，請重新登入，確保帳號資料為最新狀態')
      if (identity === 'seller') return next('/sellerLogin')
      else return next('/customerLogin')
    } else if (verify.code === "403-1" || verify.code === "403-2" || verify.code === "403-5") {
      switchLoading('hide')
      alert('授權錯誤')
      if (identity === 'seller') return next('/sellerLogin')
      else return next('/customerLogin')
    } else if (verify.code === '400-3') {
      switchLoading('hide')
      alert('已向賣家提出審核申請')
      if (identity === 'seller') return next('/user/linkAccounts')
      else return next('/customer/storeLists')
    } else if (verify.code === '403-6') {
      switchLoading('hide')
      alert('你在此賣場審核未通過')
      if (identity === 'seller') return next('/user/linkAccounts')
      else return next('/customer/storeLists')
    } else if (verify.code === '403-3') {
      switchLoading('hide')
      alert('你在此賣場待審核中')
      if (identity === 'seller') return next('/user/linkAccounts')
      else return next('/customer/storeLists')
    } else {
      switchLoading('hide')
      next()
    }
  } else {
    switchLoading('hide')
    next()
  }
})

// 每次換頁都移至最頂
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0)
})

export default router
