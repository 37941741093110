<template>
  <div class="theme-yellow">
    <!-- <div class="marqee" v-if="showMarquee">
      <ul>
        <li>
          Line 與 Fb 免註冊功能正式上線啦 🎉🎉🎉
          各位團媽們可以在Fb(僅限社團)與Line
          App讓顧客下單，您的顧客若沒有註冊系統也免驚啦!
          您可以在系統輕鬆收單，趕緊試用去 😁😁😁
        </li>
      </ul>
    </div> -->
    <!-- app_isNotOpenTrailPeriod 來自 getStoreLists.js -->
    <div
      class="open-trail-area"
      v-if="app_isNotOpenTrailPeriod && currentIdentity === 'seller'"
    >
      <div class="text-end p-2">
        <button class="tw-btn tw-btn-secondary" @click="logout">登出</button>
      </div>
      <ul class="fw-bolder open-trail-teaching">
        <li class="mb-2">
          <p class="title">開通試用期流程</p>
        </li>
        <li class="mb-2">
          <p>1. 加入易團購 Line 客服官方帳號</p>
          <img
            src="@/assets/line-official/M_gainfriends_qr.png"
            alt="Line客服QRcode"
          />
        </li>
        <li class="mb-2">
          <p class="mb-2">
            2. 下圖為「易團購 Line 客服官方帳號」，接著點擊「加入好友」
          </p>
          <img
            src="@/assets/line-official/line-official.jpg"
            alt="Line客服QRcode"
            style="width: 50%"
          />
        </li>
        <li class="mb-2">
          <p class="mb-2">
            3. 接著前往「易團購 Line 客服官方帳號」輸入您在系統所申辦的帳號
          </p>
          <p class="account">您的帳號為: {{ app_userInfo.account }}</p>
          <img
            src="@/assets/line-official/line-customer-service1.png"
            alt="Line客服QRcode"
            style="width: 50%"
          />
        </li>
        <li class="mb-2">
          <p class="mb-2">
            4. 發送完成後，等待客服人員核對無誤之後即可開通您的試用期
          </p>
          <img
            src="@/assets/line-official/line-customer-service2.png"
            alt="Line客服QRcode"
            style="width: 50%"
          />
        </li>
      </ul>
    </div>
    <router-view v-if="stopMechineCanContinue"/>
    <StopMerchine v-else></StopMerchine>
  </div>
</template>

<style lang="scss">
@import "@/assets/scss/all.scss";
</style>

<script>
// vuex
import { mapState } from "vuex";
// mixin
import { GetStoreList } from './methods/API/getStoreList'
// components
import StopMerchine from './components/redirect/StopMechine.vue'

export default {
  components: { StopMerchine },
  data() {
    return {
      currentStoreId: 0,
      serverToken: '',
      forWhitchOne: '',
      showMarquee: false,
      currentIdentity: 'seller',
      stopMechineCanContinue: true,
    }
  },
  mixins: [GetStoreList],
  mounted() {
    // 將初始化頁面隱藏 (html 內)
    $('#tw-preview').addClass("tw-hidden")
    this.createFbSdk()
    this.checkMarquee()
    this.checkStopMechine()
  },
  computed: {
    ...mapState('StoreList', {
      app_ownerStores: state => state.ownerStores,
      app_customerStores: state => state.customerStores,
      app_isNotOpenTrailPeriod: state => state.isNotOpenTrailPeriod,
      app_userInfo: state => state.userInfo,
    }),
    // 是在路由做更新的
    ...mapState('CurrentParticipant', {
      participantInfo: state => state.participantInfo,
    }),
    // 系統設定
    ...mapState("SystemSetting", {
      settingForStopMachine: state => state.settingForStopMachine,
      settingForStopMachineWhiteList: state => state.settingForStopMachineWhiteList,
    }),
  },
  watch: {
    $route: {
      handler(to, from) {
        this.currentIdentity = window.localStorage.getItem('identity') ? window.localStorage.getItem('identity') : 'customer'
        
        // 除了後台模組外，其他模組都做停機檢查
        this.checkStopMechine();

        if (to.meta.currentModal === 'seller') {
          // 檢查同步 FB 留言是否還在執行
          this.initialization()
          this.checkSyncFbMessages()
        }
        console.log('App.vue 權限處理')
        // 僅限系統內部 (Line FB 頁面的權限都要另外做)
        if (!(to.meta && (to.meta.currentModal === 'general' || to.meta.currentModal === 'backend' || to.name === 'NotFound' || to.meta.currentModal === 'line' || to.meta.currentModal === 'fb' || to.name === 'MergeUser')) && to.name) {
          console.log('開始權限處理')

          // 取得系統設定
          this.$store.dispatch("SystemSetting/getSystemSetting");
          
          this.currentStoreId = parseInt(this.$route.params.storeId)
          this.$store.dispatch('StoreList/setCurrentStoreId', {
            currentStoreId: this.currentStoreId
          })
          this.getUserInfoForStoreList()
          // 檢查進入哪個模組
          if (to.meta && to.meta.currentModal) {
            if (to.meta.currentModal === 'seller') this.forWhitchOne = 'seller'
            else if (to.meta.currentModal === 'customer' && to.meta.name !== 'Lottery') this.forWhitchOne = 'customer'
            else this.forWhitchOne = 'other'
          } else {
            this.forWhitchOne = 'other'
          }
          // 如果 forWhitchOne 不屬於顧客或是賣家模組的部分，則不執行檢查是否有權限進入此賣場的功能
          if (this.forWhitchOne !== 'other') {
            // 檢查是否有權限進入賣場
            if (this.forWhitchOne === 'seller') {
              const checkCanEnter = setInterval(() => {
                if (this.app_ownerStores.loading) return
                clearInterval(checkCanEnter)
                // 判斷是否有此賣場
                console.log(this.app_ownerStores)
                const canEnter = this.app_ownerStores.data.some(item => {
                  console.log(item)
                  if (!item.store.deleted) {
                    console.log(item.storeId, this.currentStoreId)
                    return item.storeId === this.currentStoreId
                  }
                })
                console.log(`是否可以進入此賣場: ${canEnter}`)
                if (!canEnter) {
                  alert('您尚未有權限進入此賣場，原因可能是您並非為賣場擁有者或是您已刪除此賣場')
                  this.$router.push('/user/linkAccounts')
                }
              }, 100)
            } else if (this.forWhitchOne === 'customer') {
              const checkCanEnter = setInterval(() => {
                console.log(new Date())
                if (this.app_customerStores.loading) return
                clearInterval(checkCanEnter)
                // 判斷是否有此賣場
                let canEnter = this.app_customerStores.data.some(item => {
                  console.log(item)
                  if (item.verify) return item.storeId === this.currentStoreId
                })
                // 多判斷 participantId 一不一樣 (顧客模組比較特別)
                if (to.params && to.params.participantId) {
                  canEnter = parseInt(to.params.participantId) === this.participantInfo.data.id
                } else if (to.query && to.query.participantId) {
                  canEnter = parseInt(to.query.participantId) === this.participantInfo.data.id
                }
                if (!canEnter) {
                  alert('您尚未有進入此賣場的權限')
                  this.$router.push('/user/linkAccounts')
                }
              }, 100)
            }
          }
        }
      },
      immediate: true
    },
  },
  methods: {
    // 引入 FB API
    createFbSdk() {
      if (!window.FB) {
        window.fbAsyncInit = function () {
          console.log("Create FB sdk");
          FB.init({
            appId: process.env.VUE_APP_FB_APPID,
            cookie: true,
            xfbml: true,
            version: "v15.0",
          });
          FB.AppEvents.logPageView();
          (function (d, s, id) {
            var js,
              fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {
              return;
            }
            js = d.createElement(s);
            js.id = id;
            js.src = "https://connect.facebook.net/zh_TW/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
          })(document, "script", "facebook-jssdk");
        };
      }
    },
    // 檢查跑馬燈
    checkMarquee() {
      const breakTime = new Date('2022/9/31 23:59:59') * 1
      this.showMarquee = true
      // 先立馬檢查線在時間與終止時間
      if (new Date() * 1 >= breakTime) this.showMarquee = false
      else {
        const breakTimeFn = setInterval(() => {
          const now = new Date() * 1
          if (now >= breakTime) {
            this.showMarquee = false
            clearInterval(breakTimeFn)
          }
        }, 6000)
      }
    },
    // 初始化
    initialization() {
      this.serverToken = this.$methods.getCookie('serverToken')
      this.currentStoreId = this.$route.params.storeId
    },
    // 觸發檢查同步 FB 社團/粉專留言
    checkSyncFbMessages() {
      const data = [{
        storeId: this.currentStoreId,
        communityType: 35,
        check: true
      }]
      const data2 = [{
        storeId: this.currentStoreId,
        communityType: 36,
        check: true
      }]
      // 檢查社團
      this.$store.dispatch('SyncFbMessages/syncFbMessages', {
        serverToken: this.serverToken,
        data: data, // data 是 api 要的資料
        currentPage: 'backgroundHandle', // 若是 backgroundHandle 則只會 check，若是 merchandise 則是第一次是 check 第二次是同步
        checkIndex: 0, // 若大於 0，則會顯示 toast
      })
      // 檢查粉專
      this.$store.dispatch('SyncFbMessages/syncFbMessages', {
        serverToken: this.serverToken,
        data: data2,
        currentPage: 'backgroundHandle',
        checkIndex: 0,
      })
    },
    // 登出
    logout() {
      this.$methods.delCookie('serverToken')
      this.$store.dispatch('StoreList/setIsNotOpenTrailPeriod', false)
      this.$router.push('/sellerLogin')
    },
    // 檢查停機後有哪些人可以繼續使用
    checkStopMechine() {
      console.log(this.$route)
      if (this.$route.meta.currentModal === "backend" || this.$route.meta.currentModal === "general") return;
      if (this.settingForStopMachine && !this.settingForStopMachineWhiteList) this.stopMechineCanContinue = false;
      else this.stopMechineCanContinue = true;
    },
  },
}
</script>

<style lang="scss" scoped>
// 跑馬燈色系
$marquee-bg: rgb(88, 88, 86);
$marquee-border: rgb(241, 190, 95);
$marquee-transparent: rgba(0, 0, 0, 0);
// 跑馬燈上下 border 線條
@mixin border-gradient {
  display: inline-block;
  position: absolute;
  width: 100%;
  height: 3px;
  /* 後面 % 就像進度條一樣 */
  background: linear-gradient(
    to left,
    $marquee-transparent 0%,
    $marquee-border 15%,
    $marquee-border 50%,
    $marquee-border 85%,
    $marquee-transparent 100%
  );
}
// 跑馬燈
.marqee {
  height: 25px;
  margin: 3px 0px 0px;
  overflow: hidden;
  position: relative;
  /* 後面 % 就像進度條一樣 */
  background: linear-gradient(
    to left,
    $marquee-transparent 0%,
    $marquee-bg 15%,
    $marquee-bg 50%,
    $marquee-bg 85%,
    $marquee-transparent 100%
  );
  // 跑馬燈上下 border
  &::before {
    content: "";
    top: 0;
    @include border-gradient;
  }
  &::after {
    content: "";
    bottom: 0;
    @include border-gradient;
  }
}
.marqee ul {
  padding-left: 0;
  display: flex;
  list-style-type: none;
  animation: marqee 60s linear infinite;
  position: absolute;
  &:hover {
    animation-play-state: paused;
  }
}
.marqee ul li {
  white-space: nowrap;
  margin-right: 2em;
  font-weight: bold;
  font-size: 18px;
  color: whitesmoke;
  margin-top: 5px;
}
@keyframes marqee {
  0% {
    left: 100%;
    transform: translateX(0%);
  }
  100% {
    left: 0;
    transform: translateX(-100%);
  }
}

// 尚未開通，顯示開通流程
.open-trail-area {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(100, 100, 100, 0.8);
  z-index: 1000;
  overflow: scroll;
  .open-trail-teaching {
    text-align: center;
    padding: 10px 0;
    .title {
      font-size: 30px;
      background: rgb(196, 172, 67);
      color: white;
      padding: 5px;
    }
    .account {
      font-size: 30px;
      background: rgb(196, 67, 147);
      color: white;
      padding: 5px;
    }
    p {
      font-size: 30px;
      background: rgb(51, 123, 218);
      color: white;
      padding: 5px;
    }
  }
}
</style>
