import { systemApi } from "../../methods/API/normal/systemApi"

export default {
  namespaced: true,
  state: {
    // (針對整個系統)
    settingForStopMachine: false, // 停機 
    settingForLineLive: false, // 直播
    settingForStoreDiscount: false, // 賣場促銷

    // (針對使用者設定)
    settingForStopMachineWhiteList: false, // 停機後，還能繼續使用的使用者
    settingForLinekeywords: false, // 自定義 Line 關鍵字功能
  },
  mutations: {
    SETVALUE(state, payload) {
      console.log(payload)
      // 表示跟 "系統設定" 有關
      if (payload.sourceType === null) {
        if (payload.optionName === "stopMachine") state.settingForStopMachine = payload.enabled;
        else if (payload.optionName === "lineLive") state.settingForLineLive = payload.enabled;
        else if (payload.optionName === "storeDiscount") state.settingForStoreDiscount = payload.enabled;
      }

      // 表示跟 "使用者設定" 有關
      if (payload.sourceType === "PHYSICALUSER") {
        if (payload.optionName === "customLineKeywords") state.settingForLinekeywords = payload.enabled;
        else if (payload.optionName === "stopMachineWhiteList") state.settingForStopMachineWhiteList = payload.enabled;
      }
    },
  },
  actions: {
    // 取得系統設定
    getSystemSetting(context, payload) {
      systemApi.getSystemSettingByPhysicalUser().then(res => {
        console.log(res)
        if (res.code === "200") {
          const settings = res.data;
          settings.forEach(setting => {
            context.commit('SETVALUE', setting)
          })
        }
      }).catch(err => {
        console.log(err);
      }).finally(() => {

      })
    },
  },
}