// 引入 cookie 設定
import { setCookie, getCookie, delCookie } from '../../global/cookie'

class SystemSetting {
  constructor(id, sourceType, sourceId, optionName, enabled, description) {
    this.id = id;
    this.sourceType = sourceType;
    this.sourceId = sourceId;
    this.optionName = optionName;
    this.enabled = enabled;
    this.description = description;
  }
}

class SystemApi {
  /**
  * 設定系統設定 (僅限後台人員使用)
  * @param {*} data 
  * @returns promise
  * 
  * data
  * [
  * 		{
  * 			  "id": systemSetting id,
  * 		    "sourceType": 1(physicalUser) || null(系統本身),
  * 		    "sourceId": physicalUser id || null,
  * 		    "optionName": 功能名稱(英文),
  * 		    "enabled": 是否開啟功能,
  * 		    "description": 功能描述
  * 		}...
  * ]
  */
  #setSystemSetting(data) {
    const api = `${process.env.VUE_APP_API}/system/setSystemSetting`
    const header = {
      authorization: getCookie("b_serverToken")
    }
    const result = new Promise((resolve, resject) => {
      $.ajax({
        type: "POST",
        async: true,
        url: api,
        headers: header,
        data: JSON.stringify(data),
        contentType: 'application/json',
        success: resolve,
        error: resject,
      });
    })
    return result
  }

  /**
   * 系統設定 -> 開放「Line 自訂關鍵字功能」
   * @param {*} data 
   * @returns promise
   * 
   * data
   * [
   *    {
   *      systemSettingId: systemSetting id,
   *      physicalUserId: 要針對哪個實體使用者開放
   *      enabled: bool (開啟或關閉)
   *    }...
   * ]
   */
  setSystemSettingForLinekeywords(data) {
    let finalData = [];
    data.forEach(item => {
      let systemSetting = new SystemSetting(item.systemSettingId, 1, item.physicalUserId, "customLineKeywords", item.enabled, "Line 自訂關鍵字功能");
      finalData.push(systemSetting);
    });
    
    return this.#setSystemSetting(finalData);
  }

  /**
   * 後台使用者抓系統設定
   * @param {*} physicalUserId 使用者 ID
   * @returns promise
   */
  getSystemSettingByBackendUser(physicalUserId) {
    const api = `${process.env.VUE_APP_API}/system/getSystemSetting?physicalUserId=${physicalUserId}`
    const header = {
      authorization: getCookie("b_serverToken")
    }

    const result = new Promise((resolve, resject) => {
      $.ajax({
        type: "GET",
        async: true,
        url: api,
        headers: header,
        success: resolve,
        error: resject,
      });
    })
    return result
  }

  /**
   * 前台使用者抓系統設定
   * @param {*} physicalUserId 使用者 ID (選填)
   * @returns promise
   */
  getSystemSettingByPhysicalUser() {
    const api = `${process.env.VUE_APP_API}/system/getSystemSetting`
    const header = {
      authorization: getCookie("serverToken")
    }

    const result = new Promise((resolve, resject) => {
      $.ajax({
        type: "GET",
        async: true,
        url: api,
        headers: header,
        success: resolve,
        error: resject,
      });
    })
    return result
  }
}

const systemApi = new SystemApi()

export { systemApi }